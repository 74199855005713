export const PRODUCT_ELABORATION = {
    type:  'product_elaboration',
    fields: [
        "product_reference"
    ]
};


export const MANUFACTURE_ELABORATION = {
    type:  'manufacture_elaboration',
    fields: [
        "manufacture_code",
        "manufacture_reference"
    ]
};

export const SUPPLIER_ELABORATION = {
    type:  'supplier_elaboration',
    fields: [
        "supplier_code",
        "supplier_reference",
    ]
};

export const PRODUCT_DETAIL_IMPORT = [
    "name",
    "description",
    "barcode",
    "unit_value",
    "country_of_origin",
    "brand_code",
    "hs_code",
    "alternative_reference",
    "primary_image",
    "category_code",
    "sub_category_code"
];

export const PRODUCT_CATEGORY_IMPORT = [
    "category_code",
];


export const PRODUCT_SUPPLIER_IMPORT = [
    "supplier_code",
    "supplier_reference",
];


export const PRODUCT_RATES_IMPORT = [
    "list_price",
    "discount",
    "purchase_price",
    "purchase_date",
    "price_note"
];


export const PRODUCT_DIMENSION_IMPORT = [
    "packaging_name",
    "qty_per_packaging",
    "volume",
    "gross_weight",
    "net_weight",
    "length",
    "width",
    "radius",
    "height",
    "default_packaging",
];


export const PRODUCT_TRANSLATION_IMPORT = [
    "locale",
    "name",
    "description",
];

export const CONTAINER_PRODUCT_IMPORT = [
    "product_reference",
    "quantity",
    "base_price",
    "qty_per_packaging",
    "volume",
    "weight",
    "net_weight",
    "length",
    "width",
    "radius",
    "height",
    "packaging_name"
];

export const ORDER_IMPORT = [
    "company_reference",
    "order_reference",
    "product_reference",
    "quantity",
    "base_price",
    "discount",
    "stock",
    "order_date"
];


export const ORDER_STOCK_IMPORT = [
    "company_reference",
    "order_reference",
    "product_reference",
    "base_price",
    "stock"
];


export const QUOTATION_PRODUCT_IMPORT = [
    "name",
    "hs_code",
    "country_of_origin",
    "unit_value",
    "quantity",
    "qty_per_packaging",
    "volume",
    "gross_weight",
    "net_weight",
    "length",
    "width",
    "height",
    "radius",
    "list_price",
    "list_price_discount",
    "base_price",
    "base_price_discount",
    "delivery_date",
    "note",
    "internal_note"
];

export const QUOTATION_PRODUCT_EXTRA_IMPORT = [
    "manufacture_reference"
];


export const SUB_CATEGORY_IMPORT = [
    "category_code",
    "name",
    "reference"
];
export const CATEGORY_IMPORT = [
    "name",
    "reference",
    "sub_category_name",
    "sub_category_code"

];

export const BRAND_IMPORT = [
    "name",
    "reference"
];

export const MANUFACTURE_IMPORT = [
    "name",
    "reference"
];

export const SUPPLIER_IMPORT = [
    "name",
    "reference"
];


export const LEAD_IMPORT = {
    type:  'company_lead',
    entity:  'company_lead',
    fields: [
        "contact_first_name",
        "contact_last_name",
        "business_name",
        "business_email",
        "business_phone",
        "business_country (ISO CODE)",
        "company_note",
        "company_employees",
        "company_industry",
        "company_website",
        "internal_note",
        "last_contact_date",
        "priority (low|medium|high)",
        "estimated_value",
        "company_revenue",
        "probability_percentage",
    ]
};



export const PRODUCT_DOCUMENT_TEMPLATE = [
    {field: "alternative_reference", type: "string"},
    {field: "manufacture_reference", type: "string"},
    {field: "product_reference", type: "string"},
    {field: "name", type: "string"},
    {field: "description", type: "string"},
    {field: "name_translated", type: "string"},
    {field: "description_translated", type: "string"},
    {field: "unit_value", type: "string"},
    {field: "country_of_origin", type: "string"},
    {field: "brand_name", type: "string"},
    {field: "hs_code", type: "string"},
    {field: "barcode_image", type: "image"},
    {field: "barcode", type: "string"},
    {field: "primary_image", type: "image"},
    {field: "category_code", type: "string"},
    {field: "category_name", type: "string"},
    {field: "sub_category_code", type: "string"},
    {field: "sub_category_name", type: "string"},
    {field: "packaging_name", type: "string"},
    {field: "qty_per_packaging", type: "string"},
    {field: "volume", type: "string"},
    {field: "gross_weight", type: "string"},
    {field: "net_weight", type: "string"},
    {field: "length", type: "string"},
    {field: "width", type: "string"},
    {field: "radius", type: "string"},
    {field: "height", type: "string"},
];

