import React, { useEffect, useState } from "react";
import {useParams, useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../../../../components/common/form/input-text";
import InputSelect from "../../../../../components/common/form/input-select";
import {  Warning } from "../../../../../components/common/alert/banner";
import {
    PRODUCTS,
    CATALOG, COMPANY
} from "../../../../endpoints";
import EditFooter from "../../../../../components/partials/footer/edit-footer";
import {
    getPathParam,
    getSelectParam, getSelectParams, getUnitSelectParam,
    getUnitSelectParams,
    getWebPathParam
} from "../../../../../utils/converter";
import {deleteProductImage, putProduct} from "../../../../../api/catalog";
import { useTranslation } from "react-i18next";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType
} from "../../../../../constants/permissions";
import toast from "react-hot-toast";
import {fetchCountries, fetchUnits} from "../../../../../reducers/configReducer";
import {
    BASE,
    BRANDS, PRODUCT,
    MANUFACTURES,
    PRODUCT_ENCCODES
} from "../../../../../api/endpoints";
import DynamicSearch from "../../../../../components/partials/common/dynamic-search";
import UploadFile from "../../../../../components/partials/upload/upload-file";
import placeholder from "../../../../../resources/images/placeholder.jpeg";
import LoaderIcon from "../../../../../resources/icons/LoaderIcon";
import {DownloadIcon, EyeIcon, TrashIcon} from "@heroicons/react/outline";
import FsLightbox from "fslightbox-react";
import InputEditor from "../../../../../components/common/form/input-editor";
import {getSubCategories} from "../../../../../api/category";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getFileType} from "../../../../../constants/file-extension";
import {getElement} from "../../../../../api/config";
import { APPROVED, TO_APPROVE } from "../../../../../constants/config";
import FormPopup from "../../../../../components/common/popup/form-popup";
import GenerateEncoding from "./generate-encoding";
import InputTags from "../../../../../components/common/form/input-tags";

const ProductDetail = () => {
  const { user } = useSelector((state) => state);
  const { loggedCompany } = user;
  const { isCountriesLoading, countries, unitList, isUnitsLoading } = useSelector((state) => state.config);
  const { company } = useSelector((state) => state.userCompany);

  const dispatch = useDispatch();
  const { t } = useTranslation();

    const { productDetail, categories, isLoadingCategories, setProductDetail, isActiveSaveCheck, setActiveSaveCheck, isConfirmSave, onConfirmUpdate } = useOutletContext();

  const {
    register,
    handleSubmit,
    control,
    setValue,
      watch,
      formState: { errors, isDirty }
  } = useForm({ mode: "onBlur" });
  let { id } = useParams();



  // put product
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [updateException, setUpdateException] = useState(false);
  const [isRemoveLoading, setRemoveLoading] = useState(false);
  const [cleanData, setCleanData] = useState(false);
  const [toggleSingle, setToggleSingle] = useState(false);
  const [singleImage, setSingleImage] = useState(false);


  const [isLoadingSubCategory, setLoadingSubCategory] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const { permissions } = useSelector((s) => s.userCompany);
  const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.EDIT);
  const canEditCategory = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_CATEGORY);

  const canEncode = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_GENERATE_ENCODE);


  const [isOpenEncoding, setOpenEncoding] = useState(false);
  const [isOpenManage, setOpenManage] = useState(false);
  const [encodingData, setEncodingData] = useState({});


    // get Brands
  useEffect(() => {
    if(productDetail !== null){
      for (const k in productDetail) {
        if (typeof productDetail[k] !== "object") {
          setValue(k, productDetail[k]);
        }
        if (typeof productDetail[k] === "object" && ( k === 'country_origin' || k === 'company_brand' || k === 'company_manufacture' || k === 'category'  || k === 'sub_category' )) {
            setValue(k, getSelectParam(productDetail[k], "name"));
        }
        if (typeof productDetail[k] === "object" && ( k === 'unit_value' )) {
            setValue(k, getUnitSelectParam(productDetail[k]));
        }

      }

        if(productDetail.tags){
            setValue('tags', productDetail.tags ? productDetail.tags.split('|') : []);
        }

      if(productDetail && productDetail.category){
          fetchSubCategories(productDetail.category.id);
      }

    }

    dispatch(fetchCountries());
    dispatch(fetchUnits());

      // eslint-disable-next-line
  }, [productDetail, setValue, dispatch]);


    useEffect(() => {
        setActiveSaveCheck(isDirty);
        // eslint-disable-next-line
    }, [isDirty]);



    useEffect( () => {
        if (isActiveSaveCheck){
            let data = watch();
            setValue('picture', null);
            updateProduct(data);
            onConfirmUpdate()
        }
        // eslint-disable-next-line
    }, [isConfirmSave]);




    const fetchSubCategories = async (category_id) => {
        if (loggedCompany) {
            setLoadingSubCategory(true);
            try {
                const res = await getSubCategories({
                    category_id: category_id,
                });

                if(res.sub_categories.length === 0){
                    setValue('sub_category', null)
                }


                setSubCategories(getSelectParams(res.sub_categories, "name"));
                setLoadingSubCategory(false);
            } catch (e) {
                setSubCategories([]);
            } finally {
                setLoadingSubCategory(false);
            }
        }
    }


    const download = async (id, path, filename) => {


        let base = false;
        try {
            const res = await getElement(getPathParam([COMPANY, loggedCompany.id, PRODUCT, productDetail.id, BASE, id]));
            base = res.base64;
        } catch (e) {
            base = false
        }

        const ext = path.split('.').pop();
        const fileType = getFileType(ext)
        if (fileType && base) {
            // Convert base64 to blob
            const byteCharacters = atob(base);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: fileType });

            // Create an object URL
            const url = URL.createObjectURL(blob);

            // Create a new link element for download
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename; // Ensure `filename` is defined and valid

            // Append to the DOM, trigger `click`, then clean up
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            URL.revokeObjectURL(url);
        }

    };


   const getFilteredOptions = () => {
        if(unitList.length > 0){

            let result = unitList.reduce(function (r, a) {
                    let flag = a.code;
                    r[flag] = r[flag] || [];
                    r[flag].push(a.unit_values);
                    return r;
                }, Object.create(null));

            let data = [];
            // eslint-disable-next-line
            Object.keys(result).map((r) => {
                data.push({label: t("app.products."+r), options: getUnitSelectParams(result[r][0]) });
            });
            return data;
        }
        return [];
    }


    const removeImage = async () => {
        setRemoveLoading(true);
        deleteProductImage({company_id: loggedCompany.id, product_id: id})
            .then(response => {
                setRemoveLoading(false);
                setProductDetail(response);
                toast.success(t("app.products.update_success"))
            }).catch(e => {
            setUpdateException(e.message);
            setRemoveLoading(false);
        });
    };


  const updateProduct = async (data) => {
    if (loggedCompany) {
      data["id"] = id;
        if(data.tags){
            data['tags'] = data.tags.join('|');
        }
      setCleanData(false);
      setUpdateLoading(true);
      putProduct({ company_id: loggedCompany.id, product_id: id, data })
        .then((response) => {
          setUpdateLoading(false);
          toast.success(t("app.products.update_success"))
          setUpdateException(false);
          setProductDetail(response);
          setCleanData(true);
        })
        .catch((e) => {
          setUpdateLoading(false);
          setUpdateException(e.message);
        });
    }
  };


  const onSubmit = async (data) => {
      setValue('picture', null);
      await updateProduct(data);
  };


    const disabledProductCode = () => {
        if (company.product_manage_status && company.update_product_code){
            return productDetail.status === APPROVED;
        }else{
            return !company.update_product_code;
        }
    };


    const isRequiredFiled = () => {
        if (company.product_manage_status) {
            return (productDetail.status === TO_APPROVE || productDetail.status === APPROVED);
        }
        return false;
    };



  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT, permissionType.VIEW)]} renderBaseOnFail={true}>

        <div className="absolute">
            <FsLightbox
                toggler={ toggleSingle }
                disableLocalStorage={true}
                sources={ [singleImage]}
                type="image"
            />

        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shadow ">
                <div className="bg-white py-6 px-4 sm:p-6">

                    <div className="grid grid-cols-2 gap-6">


                        <div className={"col-span-full -mb-6"}>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-4">{t("app.products.image_cover")}</label>
                        </div>

                        <div className="col-span-full flex items-center gap-x-8 ">


                            <div style={{background: productDetail && productDetail.picture ? '#F4F7FC' : '#f7f7f7'}}
                                 className={" border-2 border-gray-300 border-dashed rounded-md group block w-2/6 p-2 aspect-w-10  relative  overflow-hidden"}>


                                {
                                    productDetail && productDetail.picture && (
                                        <>
                                            <div
                                                className={'absolute right-[4.5rem] bg-gray-900 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'}
                                                onClick={() => {
                                                    download(productDetail.picture.id, productDetail.picture.reference, productDetail.product_reference)
                                                }}>
                                                <DownloadIcon className={'h-5 w-5 text-white'}/>
                                            </div>

                                            <div
                                                className={'absolute right-10 bg-blue-1000 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'}
                                                onClick={() => {
                                                    setSingleImage(productDetail.picture.reference);
                                                    setToggleSingle(!toggleSingle);
                                                }}>
                                                <EyeIcon className={'h-5 w-5 text-white'}/>
                                            </div>

                                            {canEdit && (
                                                isRemoveLoading ? (
                                                    <div
                                                        className={
                                                            "absolute right-2 bg-red-400 rounded-full p-1 top-2 z-10 block cursor-pointer"
                                                        }
                                                    >
                                                        <LoaderIcon
                                                            className="animate-spin w-4 h-4 fill-current shrink-0 text-white"/>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            "absolute right-2 bg-red-400 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer"
                                                        }
                                                        onClick={() => {
                                                            removeImage();
                                                        }}
                                                    >
                                                        <TrashIcon className={"h-5 w-5 text-white"}/>
                                                    </div>
                                                )
                                            )

                                            }
                                        </>
                                    )
                                }

                                <img
                                    src={productDetail?.picture?.reference ?? placeholder}
                                    alt=""
                                    className="object-contain h-40 w-full pointer-events-none group-hover:opacity-25"
                                />
                            </div>

                            {
                                canEdit && (
                                    <div className="w-full ">
                                        <UploadFile
                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                            fileTypeLabel={'JPG, PNG'}
                                            image={true}
                                            cleanData={cleanData}
                                            onUpload={(data) => {
                                                setValue('picture', {media_url: data.media_url});
                                            }}
                                            onCancel={(r) => {
                                                if (r) {
                                                    setValue('picture', null);
                                                }
                                            }}
                                            extraClass={'h-44'}
                                            onException={(e) => {
                                            }}
                                            isLoading={false}
                                        />

                                    </div>
                                )
                            }

                        </div>

                        <div>
                            <InputText
                                id={"product_reference"}
                                type={"text"}
                                errors={errors.product_reference}
                                input={{
                                    ...register("product_reference", {required: true}),
                                }}
                                label={t("app.products.product_code")}
                                isRequired={true}
                                isDisabled={disabledProductCode()}
                            />
                            {
                                (!disabledProductCode() && canEncode) && (
                                    <div className={"flex justify-end"}>
                                        <div
                                            className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer"
                                            onClick={() => {
                                                setOpenEncoding(true)
                                            }}>
                                            {t("app.catalog.encode_product")}
                                        </div>
                                    </div>
                                )
                            }

                        </div>

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{
                                ...register("name", {
                                    required: true,
                                    validate: value => value.length <= 72 || t("app.catalog.max_input_name")
                                })
                            }}
                            label={t("app.form.name")}
                            isRequired={true}
                            isDisabled={!canEdit}
                        />


                        <div>
                            <InputText
                                id={"barcode"}
                                type={"text"}
                                errors={errors.barcode}
                                input={{
                                    ...register("barcode", {
                                        required: false,
                                    }),
                                }}
                                isDisabled={!canEdit}
                                label={'Barcode'}
                                isShowLabel={true}
                            />
                            {
                                productDetail.barcode_file && (
                                    <div className={"flex justify-between after:flex-1"}>
                                        <div
                                            className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer"
                                            onClick={() => {
                                                download(productDetail.barcode_file.id, productDetail.barcode_file.reference, productDetail.product_reference)
                                            }}>
                                            Download barcode
                                        </div>
                                    </div>
                                )
                            }


                        </div>


                        <Controller
                            name="unit_value"
                            rules={{required: true}}
                            control={control}
                            render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                <InputSelect
                                    label={t("app.products.unit_value")}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);

                                    }}
                                    options={getFilteredOptions()}
                                    value={value}
                                    isLoading={isUnitsLoading}
                                    isDisabled={isUnitsLoading || !canEdit}
                                    errors={error}
                                    isRequired={true}
                                />
                            )}
                        />

                        <Controller
                            name="country_origin"
                            rules={{required: isRequiredFiled()}}
                            control={control}
                            render={({
                                         field: {onChange, value, name},
                                         fieldState: {error},
                                     }) => (
                                <InputSelect
                                    label={t("app.products.country_origin")}
                                    name={name}
                                    options={countries}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isCountriesLoading}
                                    isDisabled={isCountriesLoading || !canEdit}
                                    isClearable={true}
                                    isRequired={isRequiredFiled()}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="company_brand"
                            rules={{required: false}}
                            control={control}
                            render={({
                                         field: {onChange, value, name},
                                         fieldState: {error},
                                     }) => (
                                <DynamicSearch
                                    selectLabel={t("app.catalog.brands")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, BRANDS])}
                                    responseLabel={'brands'}
                                    label={'name'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    isClearable={true}
                                    isRequired={false}
                                    isDisabled={!canEdit}

                                />
                            )}
                        />


                        <InputText
                            id={"hs_code"}
                            type={"text"}
                            errors={errors.hs_code}
                            input={{...register("hs_code", {required: isRequiredFiled()})}}
                            label={'Hs Code'}
                            isDisabled={!canEdit}
                            isRequired={isRequiredFiled()}

                        />


                        <InputText
                            id={"alternative_reference"}
                            type={"text"}
                            errors={errors.alternative_reference}
                            input={{
                                ...register("alternative_reference", {
                                    required: false,
                                }),
                            }}
                            isDisabled={!canEdit}
                            label={t("app.products.alternative_reference")}
                        />


                        <div className="col-span-2">
                            <Controller
                                name="description"
                                rules={{required: false}}
                                control={control}
                                render={({
                                             field: {onChange, value, name},
                                             fieldState: {error},
                                         }) => (
                                    <InputEditor
                                        label={t("app.products.description")}
                                        errors={error}
                                        defaultValue={value}
                                        name={name}
                                        isDisabled={!canEdit}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>


                        <div className="col-span-2">
                            <Controller
                                name="tags"
                                rules={{required: false}}
                                control={control}
                                render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                    <InputTags
                                        label={"Tags"}
                                        name={name}
                                        value={watch('tags') ? watch('tags') : []}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>


                        {/*{*/}
                        {/*    (productDetail?.tags ?? '').length > 0 && (*/}
                        {/*        <div className="col-span-2 ">*/}

                        {/*            <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">*/}
                        {/*                Tags*/}
                        {/*            </label>*/}

                        {/*            {*/}
                        {/*                productDetail.tags.split('|').map((tag, index) => (*/}
                        {/*                    <span key={index}*/}
                        {/*                          className="inline-flex mr-2 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">*/}
                        {/*                        {tag}*/}
                        {/*                    </span>*/}
                        {/*                ))*/}
                        {/*            }*/}


                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}


                    </div>

                    <div className="border-b pb-3 mt-10">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            {t("app.products.manufacture")}
                        </h2>
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-6">


                        <Controller
                            name="company_manufacture"
                            rules={{required: (isRequiredFiled() || (watch('manufacture_reference')?.length ?? 0) > 0)}}
                            control={control}
                            render={({
                                         field: {onChange, value, name},
                                         fieldState: {error},
                                     }) => (
                                <DynamicSearch
                                    selectLabel={t("app.catalog.manufacture")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, MANUFACTURES])}
                                    responseLabel={'manufactures'}
                                    label={'name'}
                                    error={error}
                                    value={value}
                                    isClearable={true}
                                    isDisabled={!canEdit}
                                    name={name}
                                    onChange={onChange}
                                    isRequired={(isRequiredFiled() || (watch('manufacture_reference')?.length ?? 0) > 0)}
                                />
                            )}
                        />

                        <InputText
                            id={"manufacture_reference"}
                            type={"text"}
                            errors={errors.manufacture_reference}
                            input={{
                                ...register("manufacture_reference", {
                                    required: (isRequiredFiled() || (watch('company_manufacture') !== null && watch('company_manufacture') !== undefined))
                                }),
                            }}
                            label={t("app.products.manufacture_code")}
                              isRequired={(isRequiredFiled() || (watch('company_manufacture') !== null && watch('company_manufacture') !== undefined))}
                            isDisabled={!canEdit}

                        />
                    </div>


                    {
                        canEditCategory && (
                            <>
                                <div className="border-b pb-3 mt-10">
                                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                                        {t("app.products.category")}
                                    </h2>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-6">

                                    <Controller
                                        name="category"
                                          rules={{ required: isRequiredFiled() }}
                                        control={control}
                                        render={({
                                                     field: { onChange, value, name },
                                                     fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={t("app.products.select_category")}
                                                name={name}
                                                options={categories}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    fetchSubCategories(e.id);
                                                }}
                                                isLoading={isLoadingCategories}
                                                isDisabled={isLoadingCategories || !canEdit}
                                                errors={error}
                                                isMulti={false}
                                                isRequired={isRequiredFiled()}
                                            />
                                        )}
                                    />





                                    <Controller
                                        name="sub_category"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                                     field: { onChange, value, name },
                                                     fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={t("app.products.select_sub_category")}
                                                name={name}
                                                options={subCategories}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                isLoading={isLoadingSubCategory}
                                                isDisabled={isLoadingSubCategory || subCategories.length === 0 || !canEdit}
                                                errors={error}
                                                isMulti={false}
                                            />
                                        )}
                                    />

                                </div>
                            </>
                        )
                    }





                    {/* Warning */}
                    {updateException && <Warning message={updateException} />}
                </div>

                <EditFooter
                    backLink={getWebPathParam([CATALOG, PRODUCTS])}
                    backLabel={t("app.products.back")}
                    btnLabel={t("app.form.save")}
                    isDisabled={!canEdit}
                    isLoading={isUpdateLoading}
                />
            </div>
        </form>


          {
              isOpenEncoding && (
                  <Encoding
                      isOpen={isOpenEncoding}
                      setIsOpen={setOpenEncoding}
                      onGenerate={(r) => {
                          setEncodingData(r);
                          setOpenManage(true);
                      }}
                  />
              )
          }

          {
              (!disabledProductCode() && canEncode) && (
                  <GenerateEncoding
                      isOpenPanel={isOpenManage}
                      setOpenPanel={setOpenManage}
                      productDetail={productDetail}
                      encodingData={encodingData}
                      onUpdateProductDetail={(r) => {
                          setProductDetail(r);
                          setOpenManage(false);
                      }}
                  />
              )
          }



    </BasePermissionWrapper>
  );
};




export const Encoding = ({ isOpen, setIsOpen, onGenerate }) => {


    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const {
        control,
    } = useForm({ mode: "onBlur" });



    return (
        <FormPopup
            title={t("app.catalog.encode_product")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >

                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                        <div className="col-span-2">
                            <div className="col-span-2">
                                <Controller
                                    name="encoding"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <DynamicSearch
                                            selectLabel={t("app.catalog.encoding")}
                                            endpoint={getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODES])}
                                            responseLabel={'product_encodes'}
                                            label={'title'}
                                            errors={error}
                                            value={value}
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e);
                                                onGenerate(e);
                                                setIsOpen(false);
                                            }}
                                            isClearable={false}
                                            isRequired={true}
                                            isDisabled={false}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

        </FormPopup>
    )
}


export default ProductDetail;
