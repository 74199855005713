import React, {useEffect, useState} from "react";
import InputText from "../../../components/common/form/input-text";
import InputSubmit from "../../../components/common/form/input-submit";
import InputSelect from "../../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../components/common/alert/banner";
import {CATALOG, PRODUCT, PRODUCT_DETAIL} from "../../endpoints";
import {getUnitSelectParams, getWebPathParam} from "../../../utils/converter";
import { useNavigate } from "react-router-dom";
import { postProduct } from "../../../api/catalog";
import {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific, permissionType, sp} from "../../../constants/permissions";
import {fetchCountries, fetchUnits} from "../../../reducers/configReducer";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import { refreshFilters } from "../../../reducers/userCompanyReducer";
import GenerateEncoding from "./edit/detail/generate-encoding";
import { Encoding } from "./edit/detail/product-details";

export default function AddProduct({ isOpen, setIsOpen }) {
  const { loggedCompany } = useSelector((state) => state.user);
  const { isCountriesLoading, countries, unitList, isUnitsLoading } = useSelector((state) => state.config);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
      setValue
  } = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateProductLoading, setCreateProductLoading] = useState(false);
  const [createProductException, setCreateProductException] = useState(false);
  const { permissions } = useSelector((s) => s.userCompany);

  const canEncode = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_GENERATE_ENCODE);
  const [isOpenEncoding, setOpenEncoding] = useState(false);
  const [isOpenManage, setOpenManage] = useState(false);
  const [encodingData, setEncodingData] = useState({});


  const storeProduct = async (data) => {
    if (loggedCompany) {

      setCreateProductLoading(true);

      postProduct({ company_id: loggedCompany.id, data })
        .then((response) => {
          // console.log(response);
          setCreateProductLoading(false);
            dispatch(subscriptionUsageLimits(loggedCompany.id));
            let canView = hasPermissionFor(
              permissions,
                permissionGroup.PRODUCT,
                permissionType.EDIT
          );

            dispatch(refreshFilters({ company_id: loggedCompany.id }));


          if(canView){
            navigate(getWebPathParam([CATALOG, PRODUCT, response.id, PRODUCT_DETAIL]));
          }
          setIsOpen(false)
        })
        .catch((e) => {
          setCreateProductException(e.message);
          setCreateProductLoading(false);
        });
    }
  };

  useEffect(() => {
      dispatch(fetchCountries());
      dispatch(fetchUnits());
  }, [loggedCompany, navigate, dispatch, setValue]);


    const getFilteredOptions = () => {
        if(unitList.length > 0){

            let result = unitList.reduce(function (r, a) {
                let flag = a.code;
                r[flag] = r[flag] || [];
                r[flag].push(a.unit_values);
                return r;
            }, Object.create(null));

            let data = [];
            // eslint-disable-next-line
            Object.keys(result).map((r) => {
                data.push({label: t("app.products."+r), options: getUnitSelectParams(result[r][0]) });
            });
            return data;
        }
        return [];
    }

  const onSubmit = async (data) => {
      if (data['product_reference'] !== data['product_reference_tmp']){
          delete data['generated_string'];
          delete data['encode_formatted'];
      }
    await storeProduct(data);
  };

  return (
      <FormPopup
          title={t("app.products.new_product")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >
          <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                      <InputText
                          isRequired={true}
                          id={"name"}
                          type={"text"}
                          errors={errors.name}
                          input={{ ...register("name", { required: true }) }}
                          label={t("app.form.name")}
                      />

                      <div>
                          <InputText
                              isRequired={true}
                              id={"product_reference"}
                              type={"text"}
                              errors={errors.product_reference}
                              input={{ ...register("product_reference", { required: true }) }}
                              label={t("app.products.product_code")}
                          />
                          {
                              (canEncode) && (
                                  <div className={"flex justify-end"}>
                                      <div className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer"
                                          onClick={() => {
                                              setOpenEncoding(true)
                                          }}>
                                          {t("app.catalog.encode_product")}
                                      </div>
                                  </div>
                              )
                          }
                      </div>

                      <Controller
                          name="unit_value"
                          rules={{ required: true }}
                          control={control}
                          render={({ field: { onChange, value, name }, fieldState: { error }}) => (
                              <InputSelect
                                  label={t("app.products.unit_value")}
                                  name={name}
                                  onChange={(e) => {
                                      onChange(e);

                                  }}
                                  options={getFilteredOptions()}
                                  value={value}
                                  isLoading={isUnitsLoading}
                                  isDisabled={isUnitsLoading}
                                  isRequired={true}
                                  errors={error}
                              />
                          )}
                      />

                      <Controller
                          name="country_origin"
                          rules={{ required: false }}
                          control={control}
                          render={({
                                       field: { onChange, value, name },
                                       fieldState: { error },
                                   }) => (
                              <InputSelect
                                  label={t("app.products.country_origin")}
                                  name={name}
                                  options={countries}
                                  value={value}
                                  onChange={(e) => {
                                      onChange(e);
                                  }}
                                  isLoading={isCountriesLoading}
                                  isDisabled={isCountriesLoading}
                                  isClearable={true}
                                  errors={error}
                              />
                          )}
                      />




                      <InputText
                          id={"hs_code"}
                          type={"text"}
                          errors={errors.hs_code}
                          input={{ ...register("hs_code", { required: false }) }}
                          label={'Hs Code'}
                      />


                  </div>

                  {/* Warning */}
                  {createProductException && (
                      <Warning message={createProductException} />
                  )}

                  <div className="flex before:flex-1 items-center justify-between mt-6">
                      <InputSubmit
                          isLoading={isCreateProductLoading}
                          label={t("app.products.add_product")}
                      />
                  </div>
              </form>


              {
                  canEncode && isOpenEncoding && (
                      <Encoding
                          isOpen={isOpenEncoding}
                          setIsOpen={setOpenEncoding}
                          onGenerate={(r) => {
                              setEncodingData(r);
                              setOpenManage(true);
                          }}
                      />
                  )
              }

              {
                  (canEncode) && (
                      <GenerateEncoding
                          isOpenPanel={isOpenManage}
                          setOpenPanel={setOpenManage}
                          encodingData={encodingData}
                          onInsertCode={(string, code, formatted, tags) => {
                              setValue('product_reference', code);
                              setValue('product_reference_tmp', code);
                              setValue('generated_string', string);
                              setValue('encode_formatted', formatted);
                              setValue('tags', tags);
                              setOpenManage(false);
                          }}
                      />
                  )
              }
          </SubscriptionWrapper>
      </FormPopup>
  );
}
