import {useTranslation} from "react-i18next";
import { TagsInput } from "react-tag-input-component";

export default function InputTags ({name, isFullW = false, isShowPlaceholder = true, isDisabled = false, value, label, isShowLabel = true, isRequired, id, errors, warning, warningRegex, onChange}){

    const { t } = useTranslation();
    return (
        <div className={isFullW  ? 'w-full' : ''}>
            {
                isShowLabel && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }


            <TagsInput
                value={value ? value : []}
                onChange={(r) => {
                    onChange(r)
                }}
                classNames={{input: "form-input w-full"}}
                name={name}
                placeholder={isShowPlaceholder ? t("app.common.placeholder", { field: label.toLowerCase() }) : ''}
            />


            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}
            {errors && errors.type === 'custom'  && (  <p className="mt-2 text-sm text-red-600">{errors.message}</p>)}
        </div>

    )
};
